import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../utils/functions/common";

const initialState = {
    charts: [],
    loading: false,
    error: null
};

const loadChartsStart = (state: any) => {
    return updateObject(state, {loading: true, error: null});
};

const loadChartsEnd = (state: any) => {
    return updateObject(state, {loading: false});
};

const loadChartsError = (state: any, action: any) => {
    return updateObject(state, {loading: false, error: action.error});
};

const setCharts = (state: any, action: any) => {
    return updateObject(state, {charts: action.charts, loading: false, error: null});
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.CHART_START:
            return loadChartsStart(state);
        case actionTypes.CHART_END:
            return loadChartsEnd(state);
        case actionTypes.CHARTS_SET:
            return setCharts(state, action);
        case actionTypes.CHARTS_LOAD_ERROR:
            return loadChartsError(state, action);
        default:
            return state;
    }
};

export default reducer;
