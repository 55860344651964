import axios from "axios";
import * as actions from "../../store/actions";
import * as apiEndpoints from "../consants/apiEndpoints";

export const initStatistics = (dispatch: any, config: any) => {
    console.log('[functions/statistics.tsx] initStatistics');
    return axios
        .post(apiEndpoints.API_STATISTICS, null, config)
        .catch(err => {
            console.error('[functions/statistics.tsx] initStatistics error', err);
            dispatch(actions.showToast('A szerverhez való csatlakozás sikertelen volt. Lehet, hogy nincs internetkapcsolat. Kérem próbálja meg újra.'));
        });
};
