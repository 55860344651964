import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../../utils/functions/common";

const initialState = {
  lastSyncDate: 0,
  error: false,
  loading: false
};

const syncStart = (state: any) => {
  return updateObject(state, { loading: true });
};

const syncEnd = (state: any) => {
  return updateObject(state, { loading: false });
};

const syncSuccess = (state: any, action: any) => {
  return updateObject(state, {
    lastSyncDate: action.lastSyncDate,
    error: false,
    loading: false
  });
};

const syncFail = (state: any, action: any) => {
  return updateObject(state, {
    error: true,
    loading: false
  });
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SYNC_START:
      return syncStart(state);
    case actionTypes.SYNC_END:
      return syncEnd(state);
    case actionTypes.SYNC_SUCCESS:
      return syncSuccess(state, action);
    case actionTypes.SYNC_FAIL:
      return syncFail(state, action);
    default:
      return state;
  }
};

export default reducer;
