import * as actionTypes from "../actions/actionTypes";

const initialState: any = [];

const setHospitals = (state: any, action: any) => {
    return [...action.hospitals];
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.HOSPITALS_SET:
            return setHospitals(state, action);
        default:
            return state;
    }
};

export default reducer;
