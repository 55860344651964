import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../utils/functions/common";
import {AlertInterface} from "../declarations";

export const ALERT_SYNC_INDEX = 0;
export const ALERT_DANGER_INDEX = 1;
export const ALERT_SUCCESS_INDEX = 2;

let alertObject: AlertInterface[] = [];
alertObject[ALERT_SYNC_INDEX] = {
    show: false,
    position: 'sticky',
    type: 'warning',
    callback: null,
    message: 'Az alkalmazás nem tudta feltölteni az adatokat a szerverre. Lehetséges, hogy nincs internetkapcsolata. Ide kattinta megpróbálhatja az adatokat feltölteni.'
};

alertObject[ALERT_DANGER_INDEX] = {
    show: false,
    position: 'normal',
    type: 'danger',
    callback: null,
    message: ''
};

alertObject[ALERT_SUCCESS_INDEX] = {
    show: false,
    position: 'normal',
    type: 'success',
    callback: null,
    message: ''
};

const initialState = {
    alerts: alertObject
};

const showAlert = (state: any, action: any, index: number) => {
    const updatedObject = {...state};
    const alerts = [...updatedObject.alerts];

    alerts[index].show = true;
    alerts[index].message = action.message;
    updatedObject.alerts = alerts;

    return updateObject(state, updatedObject);
};

const hideAlert = (state: any, action: any, index: number) => {
    const updatedObject = {...state};
    const alerts = [...updatedObject.alerts];

    alerts[index].show = false;
    alerts[index].message = '';
    updatedObject.alerts = alerts;

    return updateObject(state, updatedObject);
};

const showSyncAlert = (state: any) => {
    const updatedObject = {...state};
    const alerts = [...updatedObject.alerts];

    alerts[ALERT_SYNC_INDEX].show = true;
    updatedObject.alerts = alerts;

    return updateObject(state, updatedObject);
};

const hideSyncAlert = (state: any) => {
    const updatedObject = {...state};
    const alerts = [...updatedObject.alerts];

    alerts[ALERT_SYNC_INDEX].show = false;
    updatedObject.alerts = alerts;

    return updateObject(state, updatedObject);
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.ALERT_SHOW_SUCCESS:
            return showAlert(state, action, ALERT_SUCCESS_INDEX);
        case actionTypes.ALERT_HIDE_SUCCESS:
            return hideAlert(state, action, ALERT_SUCCESS_INDEX);
        case actionTypes.ALERT_SHOW_DANGER:
            return showAlert(state, action, ALERT_DANGER_INDEX);
        case actionTypes.ALERT_HIDE_DANGER:
            return hideAlert(state, action, ALERT_DANGER_INDEX);
        case actionTypes.ALERT_SHOW_SYNC:
            return showSyncAlert(state);
        case actionTypes.ALERT_HIDE_SYNC:
            return hideSyncAlert(state);
        case actionTypes.ALERT_HIDE:
            return hideAlert(state, action, action.index);
        default:
            return state;
    }
};

export default reducer;
