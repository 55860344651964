import React, {useEffect, useState} from 'react';

import Backdrop from '../Backdrop/Backdrop';
import classes from './Modal.module.scss';
import {IonButton} from "@ionic/react";

interface ModalProps {
    show: boolean,
    modalClosed: any,
    children: any
}

const Modal: React.FC<ModalProps> = (props) => {
    const [isTimeout, updateIsTimeout] = useState<boolean>(false);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (props.show && !isTimeout) {
            interval = setInterval(() => {
                updateIsTimeout(true);
            }, 1000 * 60);
        }

        return () => {
            updateIsTimeout(false);
            clearInterval(interval);
        };
    }, [props.show, isTimeout]);

    const timeoutLayout = (
        <div className="ion-text-center">
            <IonButton onClick={() => window.location.reload()}>Alkalmazás újratöltése</IonButton>
        </div>
    );

    return (
        <>
            <Backdrop
                show={props.show}
                clicked={props.modalClosed}
            />
            <div
                className={classes.Modal}
                style={{
                    transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
                    opacity: props.show ? '1' : '0'
                }}
            >
                {props.children}

                {isTimeout && timeoutLayout}
            </div>
        </>
    );
}

export default Modal;
