import * as actionTypes from "./actionTypes";

export const showToast = (message: string) => {
    return {
        type: actionTypes.TOAST_SHOW,
        show: true,
        message : message
    }
};

export const hideToast = () => {
    return {
        type: actionTypes.TOAST_HIDE
    }
};
