import React from 'react';
import {close} from "ionicons/icons";
import {IonIcon} from "@ionic/react";
import {AlertInterface} from "../../store/declarations";
import classes from './Alert.module.scss';

interface AlertPropertyInterface extends AlertInterface {
    closeHandler?: any
}

const alert: React.FC<AlertPropertyInterface> = (props) => {
    let alertClasses = [classes['alert'], classes['alert--' + props.type]];
    let closeIcon: any = null;

    if (props.position === 'sticky') {
        alertClasses.push(classes['alert--cursor-pointer']);
    }

    if (props.position !== 'sticky') {
        closeIcon = <IonIcon className={classes['alert__close-icon']} icon={close} onClick={props.closeHandler}/>
    }

    const alertTemplate = () => {
        if (props.show) {
            return (
                <div className={alertClasses.join(' ')} onClick={props.callback}>
                    {props.message}
                    {closeIcon}
                </div>
            );
        }

        return null;
    };

    return (
        <>
            {alertTemplate()}
        </>
    );
};

export default alert;
