import React from "react";
import {
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonMenu,
    IonToolbar,
    IonRow,
    IonIcon
} from "@ionic/react";
import classes from "./Menu.module.scss";
import {
    powerOutline,
    caretDownOutline,
    documentText,
    sync,
    home,
    statsChart,
    calendar, bandage
} from "ionicons/icons";
import {Link, RouteComponentProps} from "react-router-dom";
import {menuController} from "@ionic/core";
import {useDispatch} from "react-redux";
import * as actions from "../../store/actions/index";
import {role} from "../../utils/consants/auth";
import {routes} from "../../utils/consants/routes";
import Guard from "../../hoc/guard/guard";

const Menu: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch();
    const logout = () => {
        dispatch(actions.logout());
    };

    const closeMenu = () => {
        menuController.close();
    };

    return (
        <IonMenu
            side="start"
            menuId="first"
            content-id="main-content"
            className={classes["menu"]}
        >
            <IonHeader className={classes["menu__header"]}>
                <IonToolbar className={classes["menu__toolbar"]} color="primary">
                    <IonRow>
                        <img
                            className={classes["menu__avatar"]}
                            src={"./assets/photo@2x.png"}
                            alt="avatar"
                        />
                    </IonRow>
                    <IonRow className={classes["menu__title"]}>
                        Menu
                        <IonIcon
                            className={classes["menu__icon"]}
                            icon={caretDownOutline}
                        />
                    </IonRow>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList no-lines>
                    <Guard roles={[role.doctor]}>
                        <IonItem lines="none">
                            <IonIcon
                                className={classes["menu__item-icon"]}
                                icon={documentText}
                            />
                            <Link
                                to={routes.userHome + routes.homeDefault}
                                className={classes["menu__item-text"]}
                                onClick={closeMenu}
                            >Charts</Link>
                        </IonItem>
                    </Guard>

                    <Guard roles={[role.doctor]}>
                        <IonItem lines="none">
                            <IonIcon
                                className={classes["menu__item-icon"]}
                                icon={documentText}
                            />
                            <Link
                                to={routes.userHome + routes.homeCovid}
                                className={classes["menu__item-text"]}
                                onClick={closeMenu}
                            >Covid nyilatkozatok</Link>
                        </IonItem>
                    </Guard>

                    <Guard roles={[role.doctor]}>
                        <IonItem lines="none">
                            <IonIcon
                                className={classes["menu__item-icon"]}
                                icon={sync}
                            />
                            <Link
                                to={routes.sync}
                                className={classes["menu__item-text"]}
                                onClick={closeMenu}
                            >Szinkronizáció</Link>
                        </IonItem>
                    </Guard>

                    <Guard roles={[role.admin]}>
                        <IonItem lines="none">
                            <IonIcon
                                className={classes["menu__item-icon"]}
                                icon={home}
                            />
                            <Link
                                to={routes.adminHome}
                                className={classes["menu__item-text"]}
                                onClick={closeMenu}
                            >Főoldal</Link>
                        </IonItem>
                    </Guard>

                    <Guard roles={[role.admin]}>
                        <IonItem lines="none">
                            <IonIcon
                                className={classes["menu__item-icon"]}
                                icon={calendar}
                            />
                            <Link
                                to={routes.eventList}
                                className={classes["menu__item-text"]}
                                onClick={closeMenu}
                            >Események</Link>
                        </IonItem>
                    </Guard>

                    <Guard roles={[role.admin]}>
                        <IonItem lines="none">
                            <IonIcon
                                className={classes["menu__item-icon"]}
                                icon={bandage}
                            />
                            <Link
                                to={routes.hospitalList}
                                className={classes["menu__item-text"]}
                                onClick={closeMenu}
                            >Klinikák</Link>
                        </IonItem>
                    </Guard>

                    <Guard roles={[role.admin]}>
                        <IonItem lines="none">
                            <IonIcon
                                className={classes["menu__item-icon"]}
                                icon={statsChart}
                            />
                            <Link
                                to={routes.statistics}
                                className={classes["menu__item-text"]}
                                onClick={closeMenu}
                            >Statisztikák</Link>
                        </IonItem>
                    </Guard>

                    <IonItem lines="none">
                        <IonIcon
                            className={classes["menu__item-icon"]}
                            icon={powerOutline}
                        />
                        <span
                            className={classes["menu__item-text"]}
                            onClick={() => menuController.close().then(() => logout())}>Kijelentkezés</span>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;
