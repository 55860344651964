export const getAxiosConfigWithToken = (token: string) => {
    return {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
};

export const getErrorMessageFromResponse = (response: any) => {
    console.error('[http.ts] http error:', response);
    return response.data.payload.message || 'Szerver hiba!'
};
