import * as actionTypes from "./actionTypes";

export const showSuccessAlert = (message: string) => {
    return (dispatch: any) => {
        dispatch(setSuccessAlert(message));
        setTimeout(() => {
            dispatch(hideSuccessAlert());
        }, 5000);
    };
};

export const setSuccessAlert = (message: string) => {
    return {
        type: actionTypes.ALERT_SHOW_SUCCESS,
        message : message
    }
};

export const hideSuccessAlert = () => {
    return {
        type: actionTypes.ALERT_HIDE_SUCCESS
    }
};

export const showDangerAlert = (message: string) => {
    return {
        type: actionTypes.ALERT_SHOW_DANGER,
        message : message
    }
};

export const hideDangerAlert = () => {
    return {
        type: actionTypes.ALERT_HIDE_DANGER
    }
};

export const showSyncAlert = () => {
    return {
        type: actionTypes.ALERT_SHOW_SYNC
    }
};

export const hideSyncAlert = () => {
    return {
        type: actionTypes.ALERT_HIDE_SYNC
    }
};

export const hideAlert = (index: number) => {
    return {
        type: actionTypes.ALERT_HIDE,
        index: index
    }
};
